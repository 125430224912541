<template>
    <div>
        <div class="login-concent">
                <div class="img-lg">
                    <img src="../../src/assets/lg.svg" alt="">
                </div>
                <div class="img-size"><span>煤仓管理系统</span></div>
        </div>
        <div class="pocBox">
            <h1>煤仓管理系统服务协议及隐私政策</h1> 
            <h4>一、提示条款</h4>
            <p>（一）您在点击“同意本协议”之前，建议您仔细阅读本协议，特别是免责条款及隐私政策等部分，请您重点阅读。</p>
            <p>（二）您在注册并登录该平台时，即表示您已充分阅读、理解并接受了本协议的全部内容，并与煤仓管理系统达成一致，成为本平台的用户。本协议将成为您和煤仓管理系统之间具有法律效力的文书，双方均接受本协议条款的约束。</p>
            <h4>二、注册须知</h4>
            <p>（一）煤仓管理系统用户分为个人用户和企业用户，您在同意接受本协议或者注册用户时，请确认您已年满十八周岁或者您为中国合法开展经营活动或者其他业务的法人或非法人组织。</p>
            <p>（二）您应准确填写本平台要求您提供的注册信息，认真完善个人资料，提供真实有效的联系方式、电子邮件，以便能与您进行及时、有效的联系。您声明并保证,您在注册时所填写的联系方式为您本人所有，无论该手机号是否在您本人名下，均能够体现您本人的意志。</p>
            <p>（三）您确认如您代表法人及非法人组织在本平台上进行注册，则您声明并保证，您已获得公司对您的合法授权并受本协议的约束。</p>
            <h4>三、账号保管义务</h4>
            <p>（一）本平台账号由用户自行注册保管，请您务必保管好自己的账户信息及密码，确保每次登录平台进行操作后以正常步骤退出平台登陆。</p>
            <p>（二）本平台账号及密码因您个人原因泄露、遗失等导致的损失及后果，本平台不承担任何责任。</p>
            <p>（三）本平台作为云服务提供商，您通过平台提供的服务、上传、下载等业务数据，均为您完全拥有的数据，我们仅根据您的指示进行处理，您应对您业务数据来源及内容负责，若因您业务数据违反法律法规、部门规章或国家政策而导致的不良后果及责任均由您自己承担。</p>
            <h4>四、电子签章</h4>
            <p>（一）您在本平台注册之后，可以通过平台制作专属于您的电子签名制作数据，采用实名认证的方式进行数字证书申请，选择模板印章或手绘签章等印章服务制作您的个人印章，并依据您的个人意愿调用您的数字证书秘钥完成电子文件的签署，所有信息专属您个人控制。</p>
            <p>（二）您在使用个人印章签署电子文件时，本平台将为该份电子文件加盖时间戳，确定电子文件的签约时间。</p>
            <p>（三）为了确保您电子文书的合同效力，您在签章过程中本平台将收集您的签署信息，包括但不限于操作账号的设备信息、系统日志、签署人身份信息、签署方式等数据，并同步传输至本系统存证系统。</p>
            <p>（四）您通过本平台签署的电子文件将同步传输至与本平台合作的第三方公证处，您可通过存证平台申请出具公证书，证明您电子文件签署的合法性。</p>
            <h4>五、货运物流</h4>
            <p>（一）免责申明</p>
            <p>为满足客户需求，本平台提供货运物流服务，发布货物运输需求信息并获得货物运输订单信息，与托运人及承运人进行实时信息交互，在客户与承运人之间生成货运订单。在订单生成后，由于托运人或承运人原因造成的货物损失或者交通事故的，由托运人和承运人自行协商解决，与本平台无任何关联，本平台不承担任何责任。</p>
            <p>（二）托运人义务</p>
            <p>1.按照平台的设置要求，托运人必须如实填写所需托运货物运输需求信息，并对其真实性负责。</p>
            <p>2.托运人应保证对货物拥有合法的处置权，按照约定时间和地点备足货物，协助承运人装货。</p>
            <p>3.禁止托运违禁用品。</p>
            <p>4.托运人应按照平台所规定的运费标准向承运人及时支付相关费用，不得拖欠。</p>
            <p>（三）承运人义务</p>
            <p>1.承运人应按照平台要求，完整、准确的填写个人信息及车辆信息，并对其真实性负责。</p>
            <p>2.接受订单后，按照订单所要求时间及地点按照装卸货物。</p>
            <p>3.确保运输过程中个人良好的身体状况，安全驾驶。</p>
            <p>4.确保承运车辆状况良好，充分满足承运需求。</p>
            <p>5.选择合理路线，避免因交通管制等原因给自身及托运人造成的不必要的损失。</p>
            <p>6.承运人在同意接受订单后，应及时开启车辆定位服务，以确保平台对承运人在完成订单的过程中实时地理位置的收集。</p>
            <p>7.承运人在承运过程中应尽合理的注意义务，因承运人自身原因给托运人造成损失的，由承运人自己承担，平台不承担任何责任。</p>
            <p>（四）费用结算</p>
            <p>1.本平台由收取运输费和服务费的权力，并有权根据法律规定及市场行情就费用标准做出相应调整，在本平台对相关价款进行公示后，若用户继续发布或者接受本平台提供的货运信息，则视为接受该费用标准。</p>
            <p>2.订单生成后，托运人将相关费用打款至本平台账户，运输结束后，平台扣除相关费用将剩余运费划扣给承运人账户。</p>
            <h4>六、资金系统及结算制度</h4>
            <p>（一）本平台为用户提供便捷的资金收支工作，平台将根据用户提供的申请，提交银行并进行相关资料的审核，根据用户的经营及风险状况，决定是否为其开通资金平台业务。</p>
            <p>（二）用户提交申请时，应按照平台要求填写并提交相关资料，并对资料的真实性负责。</p>
            <p>（三）经银行及平台审核，若您符合资金申请条件，我们将与您另行签订《云账单B2B平台业务协议》，具体内容详见最终所签订的协议内容。</p>
            <h4>七、票据系统</h4>
            <p>（一）您在注册账户使用本平台信息时，应按照本平台的指示和要求提供您的发票信息，包括发票票面所记载的所有信息，并对其真实性负责。</p>
            <p>（二）您的发票信息将用于您在本平台服务中的发票抬头自动生成服务。</p>
            <p>（三）根据您的授权，我们将对您的发票数据进行统计整理、信息储存，并有权将您的发票数据提交给合作的金融机构，以便于其向您提供基于发票数据分析的相关服务。</p>
            <h4>八、信息使用及隐私政策</h4>
            <p>（一）本平台注重用户的信息保护，您在使用本平台提供的服务时，将视为您同意本平台收集、储存、使用、披露和保护您的用户信息。</p>
            <p>（二）信息使用情形</p>
            <p>基于以下目的，我们将使用您注册时提交以及我们收集的用户信息：</p>
            <p>1.为向您提供更好的服务，我们将会向您发送通知及信息与您进行业务沟通，包括但不限于短信验证码，信息推动等。</p>
            <p>2.为了给您提供更好的用户体验，针对您的相关信息将为您提供个性化需求展示，包括但不限于与本平台合作的第三方商业广告、新闻信息、优惠政策以及您可能感兴趣的内容。</p>
            <p>3.若超出信息收集时的目的或合理关联的范围使用您的信息，我们将及时向您发出通知并征得您的明示同意。</p>
            <p>（三）信息公开与披露</p>
            <p>本平台不会向任何个人和组织提供您的用户信息，但以下情况除外：</p>
            <p>1.在获取您明确同意或基于您的主动选择的情况下，向第三方提供您的用户信息。</p>
            <p>2.依据法律法规规定、诉讼、仲裁或司法行政等机关的要求，对外提供您的用户信息。</p>
            <p>3.为了更好的提高用户体验，我们可能委托第三方合作伙伴处理您的部分个人信息，我们仅处于合法、正当、必要、特定等目的，就服务项下的信息进行提供，第三方将承担严格的保密义务，委托事项结束后及时删除您的用户信息。</p>
            <h4>九、终止程序</h4>
            <p>（一）终止情形</p>
            <p>1.您有权按照平台规定的程序申请注销在本平台设置的账号，但平台会对您在平台的服务进行审核，若不存在违法平台规则的情况下，我们将同意注销您的账户。</p>
            <p>2.您盗用他人账号，发布违禁信息、扰乱市场秩序等行为，本平台将依规对您的账号进行冻结等，若您未在规定时间内进行整改，我们将对您的账号进行查封。</p>
            <p>（二）终止后的处理</p>
            <p>1.账户终止后，平台将继续保留您自注册登记至终止账号期间的全部用户信息及数据，保留期限为    年。</p>
            <p>2.对于您在使用该平台期间产生的违约行为，平台将依法保留对您的追责权利。</p>
            <p>3.账户终止后，您在本平台未完成的交易订单将继续有效，若交易相对方也明确表示终止该笔交易，我们将关闭交易订单，由此产生的任何损失及费用由用户自行承担。</p>
            <h4>十、免责事由</h4>
            <p>（一）选择安装与手机相匹配的软件版本，因您自身原因造成手机与软件版本不匹配从而导致损害后果的，由您自担风险。</p>
            <p>（二）由于无线网络信号不稳定，导致APP打开失败或者信息未能及时同步导致的风险由您自行承担。</p>
            <p>（三）本平台依照法律规定在保障您基础业务并提前通知的情况下，因紧急事件或者系统故障、网络信息安全、设备维护、系统升级等因素导致履行障碍的情况，本平台不承担任何责任。</p>
            <p>（四）您在本平台进行操作时，平台可能会调取第三方系统或第三方想要使用或者访问您的信息，建议您审慎操作，是否访问将由您自己做出决定，本平台不保证第三方提供信息的真实性，由此引发的任何争议及损害，平台将不承担任何责任。</p>
            <h4>十一、法律适用及其他</h4>
            <p>（一）本协议的签订、生效、解释、补充、终止、执行等均适用中华人民共和国法律，如法律无相关规定，将参见行业惯例。</p>
            <p>（二）协议管辖</p>
            <p>您在使用本平台服务过程中产生的任何争议，将由平台与您进行协商，协商不成的，任何乙方均可向平台运营商所在地的人民法院提起诉讼。</p>
            <p>（三）本协议未尽事宜，适用于平台已发布或将来可能发布的平台规则。修订该协议时，我们将提前通知您，若您在本协议发生修订后，继续使用平台服务的，即视为您同意本协议内容并接受协议约束。</p>
            <p>（四）本协议以电子版订立，对双方均发生法律效力。</p>
        </div>
        <div class="copyright-bottom">
            <div class="copyright"><span>Copyright © 山西海云启辰科技有限公司 版权所有</span><p> 晋ICP备2022001434号-1</p></div>
        </div>
    </div>
</template>
<script>
export default {
    methods:{}
}
</script>

<style lang="less" scoped>
// 头部开始
.login-concent{
    width: 100%;
    height: 76px;
    padding:8px 5%;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e4e4;
    line-height: 60px;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 10;
    .img-lg{
        float: left;
        img{
            width:48px;
            height: 48px;
            margin-top:6px;
        }
    }
    .img-size{
        float: left;
        margin-left:24px;
        span{
            font-size: 24px;
            font-weight: 600;
            color: #333;
        }
    }
}
// 头部结束
.pocBox{
    width: 100%;
    height:calc(100vh - 136px);
    overflow-y: scroll;
    // overflow: hidden;
    padding: 25px;
    box-sizing: border-box;
    margin-top:76px;
    h1{
        line-height: 120px;
        text-align: center;
        font-size: 28px;
        color: #333;
        font-weight: 600;
    }
    h4{
        font-size: 18px;
        line-height: 34px;
        color: #333;
        font-weight: 600;
        text-indent:2em;
    }
    p{
        text-indent:2em;
        font-size: 14px;
        color: #666;
        line-height: 28px;
    }
}
// 版权开始
.copyright-bottom{
  width: 100%;
  height:60px;
  padding-top:10px;
  box-sizing: border-box;
  background-color:#F6F6F6;
  position: fixed;
  bottom: 0;
  left: 0;
  .copyright{
    width:auto;
    height: 27px;
    margin: auto;
    text-align: center;
    span{
      font-size: 12px;
      line-height: 24px;
      color: #828282;
    }
    p{
      font-size: 12px;
      color: #828282;
    }
  }
}
// 版权结束





</style>